import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { toast, ToastContainer } from "react-toastify";
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import { useMemo } from "react";
import ProjectStatusBar from "./ProjectStatusBar";
import Loader from "../Loader";
import { debounce } from "lodash";

function DiscussionStatusList() {
    const dispatch = useDispatch();
    const title = "Project Status";
    const [Discussions, setDiscussions] = useState([]);
    const [filteredDiscussions, setFilteredDiscussions] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const [pageNumber,setPageNumber] = useState(1)
    const [pageNumberManager,setPageNumberManager] = useState(1)
    const [disableLoadMore,setDisableLoadMore] = useState(false);
    const [disableManagerLoadMore,setDisableManagerLoadMore] = useState(false);
    const [dataLoader,setDataLoader] = useState(false);
    const [accountManager, setAccountManager] = useState('');
    const [clients,setClients] = useState([]);
    const statusBarRef = useRef();
    
    useEffect(() => {
        dispatch(pageTitle(title));
        if(accountManager === ''){
          setDisableLoadMore(false);
          return getOpportunity(true);
        }else{
          setDisableManagerLoadMore(false);
           getOpportunityByManager(true,accountManager);
        }        
        dispatch(setBreadcrumbs([
            { label: "Home", path: "/" },
            { label: "Project Status", path: "/projectStatus", active: true }
        ]));
    }, [dispatch, title,selectedType]);

    const getOpportunity = (resetPages=false) => {
      if(pageNumber == 1)
        setLoading(true);
      if(pageNumber > 1)
        setDataLoader(true);

      const page = (resetPages)?1:pageNumber
        API.get(`/admin/discussions?page=${page}&size=30&status=${selectedType}`)
            .then((response) => {
              console.log(response.data);
            console.log("length",response.data.discussions.length);

                if (response.status === 200) {
                  if(response.data.discussions.length === 0)
                    setDisableLoadMore(true);
                  if(response.data.discussions.length <= 29)
                    setDisableLoadMore(true);
                  setDisableManagerLoadMore(true);                  
                  if(response.data.discussions.length > 29)
                    setDisableLoadMore(false);
                    setLoading(false);
                    if(resetPages){
                      setDiscussions(response.data.discussions);
                      setFilteredDiscussions(response.data.discussions); // Initialize filtered discussions
                    }else{
                      setDiscussions([...Discussions,...response.data.discussions]);
                      setFilteredDiscussions([...filteredDiscussions,...response.data.discussions]); // Initialize filtered discussions
                    }
                    setPageNumber(page+1)
                } else {
                    setLoading(false);
                    toast.error("Error while getting the data", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
                setDataLoader(false);
                setClients(response.data.managers);
            })
            .catch((error) => {
                setLoading(false);
                setDataLoader(false);
                console.error(error);
            });
    };

    const getOpportunityByManager = (resetPages=false,manager) => {
      if(pageNumberManager == 1)
        setLoading(true);
      if(pageNumberManager > 1)
        setDataLoader(true);
      const page = (resetPages)?1:pageNumberManager;
      console.log("accountManager",accountManager);
      console.log("manager",manager);

      const managerId = manager ? manager : accountManager;
      console.log("ManagerId",managerId);
      API.get(`/admin/discussionsByManager?status=${selectedType}&page=${page}&size=30&manager=${managerId}`).then((response)=>{
      console.log("length in manager",response.data.discussions.length);

        if (response.status === 200) {
          if(response.data.discussions.length === 0)
            setDisableManagerLoadMore(true);
          if(response.data.discussions.length <= 29){
            console.log("responselength",response.data.discussions.length);
            setDisableManagerLoadMore(true);
          }            
          if(response.data.discussions.length > 29)
          {
            console.log("responselength",response.data.discussions.length);
            setDisableManagerLoadMore(false);            
          }
          setLoading(false);
            if(resetPages){
              setDiscussions(response.data.discussions);
              setFilteredDiscussions(response.data.discussions); // Initialize filtered discussions
            }else{
              setDiscussions([...Discussions,...response.data.discussions]);
              setFilteredDiscussions([...filteredDiscussions,...response.data.discussions]); // Initialize filtered discussions
            }
            setPageNumberManager(page+1)
        } else {
            setLoading(false);
            toast.error("Error while getting the data", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        setDataLoader(false);
      }).catch((error) => {
        setLoading(false);
        setDataLoader(false);
        console.error(error);
    });
    }


    const discussionCounts = useMemo(() => {
        const counts = {};
        Discussions.forEach((discussion) => {
            if (counts[discussion.status]) {
                counts[discussion.status]++;
            } else {
                counts[discussion.status] = 1;
            }
        });
        return counts;
    }, [Discussions]);

     
    const handleManagerChange = (e)=>{      
      if(e.target.value === ''){
       setDisableLoadMore(true);
        setAccountManager('');
        return getOpportunity(true);
      }
      const managerId = Number(e.target.value);
       setDisableLoadMore(true); 
      setDisableManagerLoadMore(false); 
      setAccountManager(managerId); 
      getOpportunityByManager(true,managerId);
    }

    const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedType(selectedValue);        
    
        // if (selectedValue === "") {
        //     setFilteredDiscussions(Discussions); 
        // } else {
        //     const filtered = Discussions.filter(discussion => discussion.status === selectedValue);
        //     setFilteredDiscussions(filtered);
        // }
    };
 
    const getCount = () => {
      if (statusBarRef.current) {
        statusBarRef.current.getStatusCount(); 
      }
    }

   const handleStatusTypeChange = (e,disId) =>{
    const selectedValue = e.target.value;
    API.put(`/admin/updateDiscussionStatus?disId=${disId}&status=${selectedValue}`).then((response)=>{
      if(response.status === 200){
        toast.success(`Status Updated to ${selectedValue}.`, {
          position: toast.POSITION.TOP_CENTER,
      });
      if(accountManager === ''){
        getOpportunity(true);
      }else{
        getOpportunityByManager(true,accountManager);
      }
      getCount();      
      }else{
        toast.error(`${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
      });
      }
    }).catch((e)=>{
      console.log("Error in changing status:",e);
      toast.error(`Error while updating status`, {
      position: toast.POSITION.TOP_CENTER,
  });
    })
   }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
      
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; 
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        // const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      
        return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes} ${amPm}`;
      };
      
      const formatCount = (count) => {
        if (count >= 1000000) {
            return Math.round(count / 1000000) + "M";
        } else if (count >= 1000) {
            return Math.round(count / 1000) + "K+";
        }
        return count;
    };
    

    const loadMoreHandler = ()=>{
      if(!disableLoadMore && !dataLoader){
        getOpportunity();
      }
      if(disableLoadMore && !disableManagerLoadMore && !dataLoader){
        getOpportunityByManager();
      }

  }

  const handleScroll = debounce((event, editor) => {
    // if(disableLoadMore)
      return;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setDataLoader(true);
        getOpportunity()
    }
  },500);
  useEffect(()=>{

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
      
    },[handleScroll])
   
    
    return (
      <div className="main-dashboard-admin oppounity">
        {loading && (
          <div className="rotate-line-loader">
            <Bars
              visible={true}
              height="150"
              width="150"
              color="orange"
              strokeColor="orange"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
          </div>
        )}

        <div className="addOpportunities status-account">
          <div className="selectValues">
            <div className="fields">
              <label htmlFor="types">Status:</label>
              <div className="selectDropdown">
                <select
                  name="types"
                  id="types"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="">All</option>
                  <option value="On Hold">On Hold</option>
                  <option value="Work In Progress">Work In Progress</option>
                  <option value="Recurring Projects">Recurring Projects</option>
                  <option value="QA Review">QA Review</option>
                  <option value="PM Review">PM Review</option>
                  <option value="Management Review">Management Review</option>
                  <option value="Client Review">Client Review</option>
                  <option value="Pending Go-Live">Pending Go-Live</option>
                  <option value="Complete">Complete</option>
                </select>
                <div className="dropIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.971"
                    height="7.988"
                    viewBox="0 0 13.971 7.988"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                      transform="translate(20.168 -11.246) rotate(90)"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            {/* Add Account Manager filter dropdown */}
            <div className="fields">
              <label>Account Manager:</label>
              <div className="selectDropdown">
                <select
                  name="AccountManager"
                  id="accountManager"
                  value={accountManager}
                  onChange={handleManagerChange}
                >
                  <option value="">All</option>
                  {clients.map((manager) => (
                    <option key={manager} value={manager.id}>
                      {manager.fullName}
                    </option>
                  ))}
                </select>
                <div className="dropIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.971"
                    height="7.988"
                    viewBox="0 0 13.971 7.988"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                      transform="translate(20.168 -11.246) rotate(90)"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProjectStatusBar
          handleTypeChange={handleTypeChange}
          formatCount={formatCount}
          managerId={accountManager}
          ref={statusBarRef}
        />

        <div onScroll={handleScroll} className="">
          <div className="opportunityCards">
            {filteredDiscussions
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .map((discussion) => (
                <div className="cards" key={discussion.id}>
                  <div className="cardHeader">
                    <a
                      href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}
                    >
                      <h3>
                        <b>Company Name:</b> {discussion.companyName}
                      </h3>
                    </a>
                  </div>
                  <div className="cardHeader">
                    <a
                      href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}
                    >
                      <h3>
                        <b>Title:</b> {discussion.title}
                      </h3>
                    </a>
                  </div>
                  <div className="companyName">
                    <p className="service">
                      
                        <b>Status :</b>{" "}
                        <select
                          name="types"
                          id="types"
                          value={discussion.status}
                          onChange={(e) => {
                            handleStatusTypeChange(e, discussion.id);
                          }}
                        >
                          <option value="On Hold">On Hold</option>
                          <option value="Work In Progress">
                            Work In Progress
                          </option>
                          <option value="Recurring Projects">
                            Recurring Projects
                          </option>
                          <option value="QA Review">QA Review</option>
                          <option value="PM Review">PM Review</option>
                          <option value="Management Review">
                            Management Review
                          </option>
                          <option value="Client Review">Client Review</option>
                          <option value="Pending Go-Live">
                            Pending Go-Live
                          </option>
                          <option value="Complete">Complete</option>
                        </select>
                    </p>
                  </div>
                  <div className="companyName">
                    <p className="service">
                      <a
                        href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}
                      >
                        <b>Last Modified :</b>{" "}
                        {formatDate(discussion.updatedAt)}
                      </a>
                    </p>
                  </div>
                  <div className="companyName">
                    {discussion.comments.length > 0 &&
                      discussion.comments.some(
                        (comment) => comment.dueDate
                      ) && (
                        <p className="service">
                          <b>Deadlines :</b>
                        </p>
                      )}
                    {discussion.comments.map(
                      (comment, index) =>
                        comment.dueDate && (
                          <div key={index} className="deadline">
                            <a
                              href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}/${comment.id}`}
                            >
                              <p>
                                {new Date(comment.dueDate).toLocaleDateString(
                                  "en-US"
                                )}
                              </p>
                            </a>
                          </div>
                        )
                    )}
                  </div>
                  <div className="cardAmount">
                    <p>{discussion.amount}</p>
                    <div className="leftArrowIcon">
                      <a
                        href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.703"
                          height="16.531"
                          viewBox="0 0 15.703 16.531"
                        >
                          <g
                            id="Icon_feather-arrow-right"
                            data-name="Icon feather-arrow-right"
                            transform="translate(-6.5 -6.086)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M7.5,18H21.2"
                              transform="translate(0 -3.648)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_2"
                              data-name="Path 2"
                              d="M18,7.5l6.852,6.852L18,21.2"
                              transform="translate(-3.648)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {!disableLoadMore && (
          <>
            {!dataLoader && (
              <div className="load-more-btn text-center">
                <button
                  onClick={loadMoreHandler}
                  className="load-more global-icon-btn blue-btn"
                >
                  Load More
                </button>
              </div>
            )}

            {dataLoader && <Loader />}
          </>
        )}
        {!disableManagerLoadMore && (
          <>
            {!dataLoader && (
              <div className="load-more-btn text-center">
                <button
                  onClick={loadMoreHandler}
                  className="load-more global-icon-btn blue-btn second-loader"
                >
                  Load More
                </button>
              </div>
            )}

            {dataLoader && <Loader />}
          </>
        )}
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
}

export default DiscussionStatusList;
