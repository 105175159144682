import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { NavLink, Link } from "react-router-dom";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Bars } from "react-loader-spinner";
import { useSelector } from "react-redux/es/hooks/useSelector";
import ReactTooltip from "react-tooltip";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";

function Teams() {
  const dispatch = useDispatch();
  const title = "Team";
  const [members, setMembers] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const keys = { status: 1, nameEmail: "" };
  const [search, setSearch] = useState(keys);
  const [show, setShow] = useState(false);
  const [deleteableId, setDeleteableId] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const ActiveuserId = userData?.user?.id;
  dispatch(
    setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Team", path: "/team", active: true },
    ])
  );
  let SEO,
    PPC,
    Social,
    Email,
    Development,
    Design,
    Automation,
    Reputation,
    Other = 0;

  const getTeams = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    API.get("admin/addTeam", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          console.log(response.data.data);
          setMembers(response.data.data);
          setAllTeam(response.data.allTeam);
        } else {
          setLoading(false);
          console.log("error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const handleDeleteClick = (member) => {
    const id = member?.member?.id;
    setDeleteableId(id);
    setShow(true);
  };

  const handleEditClick = (member) => {
    const id = member?.member?.id;
  };

  const handleConfirmDelete = () => {
    const token = localStorage.getItem("token");
    API.delete(`/admin/delete-member/${deleteableId}/${ActiveuserId}?disable=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setShow(false);
        getTeams();
        toast.success("Member disabled successfully");
      })
      .catch((e) => {
        setShow(false);
        toast.error("Something went wrong");
        console.log(e);
      });
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
    if (name == "status") {
      if (value == 1) {
        const teamContainer = [];
        allTeam.map((team) => {
          if (team.status == 1) {
            teamContainer.push(team);
          }
        });
        setMembers(teamContainer);
      } else if (value == 0) {
        const teamContainer = [];
        allTeam.map((team) => {
          if (team.status == 0) {
            teamContainer.push(team);
          }
        });
        setMembers(teamContainer);
      }
    }
  };

  const handleClear = () => {
    setSearch(keys);
    getTeams();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.post("admin/filterTeamMembers", search)
      .then((response) => {
        setMembers(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleExport = () => {
    const token = localStorage.getItem("token");
    const exports = "1";

    API.post("admin/filterTeamMembers", search, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const data = response.data.data;
        const csvRecords = [];

        for (let item of data) {
          const Name = `"${item.firstName} ${item.lastName}"`;
          const Email = item.email;
          const AddedOn = new Date(item.createdAt).toLocaleString();
          const Status = item.status == 1 ? "Active" : "In-active";

          csvRecords.push([Name, Email, AddedOn, Status]);
        }

        const csv = Papa.unparse({
          fields: ["Name", "Email", "AddedOn", "Status"],
          data: csvRecords,
        });

        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `team-members-${new Date().toLocaleString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(pageTitle(title));
    getTeams();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentMembers = members.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(members.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };
  return (
    <div className="main-dashboard-admin">
      {loading && (
        <div className="rotate-line-loader">
          <Bars
            visible={true}
            height="150"
            width="150"
            color="orange"
            strokeColor="orange"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperclassName=""
          />
        </div>
      )}

      <div className="team-header-info">
        <form className="teamForm" onSubmit={handleSubmit}>
          {/* <div className="filter-radios">
                        <div className="input-wrap">
                            <label className="input-container">All
                                <input type="radio" id="css" name="radio" />
                                <span className="checkmark"></span>
                            </label>
                            <label className="input-container">Admins
                            <input type="radio" id="css" name="fav_language" value="CSS" />
                                <span className="checkmark"></span>
                            </label>
                            <label className="input-container">Managers
                            <input type="radio" id="css" name="fav_language" value="CSS" />
                                <span className="checkmark"></span>
                            </label>
                            <label className="input-container">Employees
                            <input type="radio" id="css" name="fav_language" value="CSS" />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div> */}
          <div className="button-wrapper">
            <div className="export-button">
              <button className="exportBtn" onClick={handleExport}>
                <div className="icon">
                  <i className="fas fa-file-export"></i>
                </div>
                Export
              </button>
            </div>
            <Link to="/AddTeam">
              <button href="#" className="primaryBtn">
                Add Team Member
              </button>
            </Link>
          </div>
          <div className="status-and-search-wrap">
            <div className="selectValues">
              <div className="fields">
                <label for="">Status</label>
                <div className="selectDropdown">
                  <select name="status" id="types" onChange={handleChange}>
                    <option value="1">Active</option>
                    <option value="0">In-Active</option>
                  </select>
                  <div className="dropIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.971"
                      height="7.988"
                      viewBox="0 0 13.971 7.988"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                        transform="translate(20.168 -11.246) rotate(90)"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="searchBar">
                        <div className="field">
                            <input type="search" placeholder="Search here" value={search.nameEmail} onChange={handleChange} />
                            <button type="submit"><i className="fa fa-search"></i></button>
                            </div>
                            <div className="search">
                                <input type="text" placeholder="Search by Name & Email" name="nameEmail" onChange={handleChange} value={search.nameEmail}/>
                                <button className="primaryBtn" type="submit">Search</button>
                                <button className="primaryBtn" type="reset" onClick={handleClear}>Clear</button>
                            </div>
                        </div> */}
          </div>
        </form>
      </div>

      <div className="teamInfoTable teams-table">
        <table border="0" width="100%">
          <thead>
            <tr>
              <th className="name">Name</th>
              <th className="title">Job Title</th>
              <th className="email">Email Address</th>
              <th className="addedOn">Added On</th>
              <th className="noOfProject">No. Of Projects</th>
              <th className="status">Status</th>
              <th className="action">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentMembers.length === 0 ? (
              <tr>
                <td colSpan={7}>
                  <div className="no-data-found no-padding">
                    <span>No members found.</span>
                  </div>
                </td>
              </tr>
            ) : (
              currentMembers.map((member) => (
                <tr>
                  <td className="name">
                    {member.firstName} {member.lastName}
                  </td>
                  <td className="name">{member.jobTitle}</td>
                  <td className="email">{member.email}</td>
                  <td className="addedOn">
                    {new Date(member.createdAt).toLocaleString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      // second: '2-digit',
                      hour12: true, // This ensures AM/PM format
                    })}
                  </td>
                  <td className="noOfProject">
                    <Link
                      className="view-profile"
                      title="View"
                      to={`/teamMember-detail/${member?.id}`}
                    >
                      {
                        <span style={{ display: "none" }}>
                          {(SEO = 0)}
                          {(PPC = 0)}
                          {(Social = 0)}
                          {(Email = 0)}
                          {(Development = 0)}
                          {(Design = 0)}
                          {(Automation = 0)}
                          {(Reputation = 0)}
                          {(Other = 0)}
                        </span>
                      }
                      {member?.projects?.length > 0
                        ? // ,PPC,SocialMedia,Email,Development,Design,Other = 0;
                          member?.projects?.map((projects) => {
                            const servicesArray = JSON.parse(
                              JSON.stringify(
                                projects.servicesInterested || "[]"
                              )
                            );
                            servicesArray.map((service) => {
                              if (service == "SEO") {
                                SEO += 1;
                              } else if (service == "PPC") {
                                PPC += 1;
                              } else if (service == "Social") {
                                Social += 1;
                              } else if (service == "Email") {
                                Email += 1;
                              } else if (service == "Development") {
                                Development += 1;
                              } else if (service == "Design") {
                                Design += 1;
                              } else if (service == "Automation") {
                                Automation += 1;
                              } else if (service == "Reputation") {
                                Reputation += 1;
                              } else if (service == "Other") {
                                Other += 1;
                              }
                            });
                          })
                        : "NA"}
                      <>
                        {SEO > 0 ? (
                          <span title="SEO">
                            <img src="assets/images/seo.png" alt="" />
                            {SEO}
                          </span>
                        ) : null}
                        {PPC > 0 ? (
                          <span title="PPC">
                            <img src="assets/images/ppc.png" alt="" />
                            {PPC}
                          </span>
                        ) : null}
                        {Social > 0 ? (
                          <span title="social">
                            <img src="assets/images/Social.png" alt="" />
                            {Social}
                          </span>
                        ) : null}
                        {Email > 0 ? (
                          <span title="Email Marketing">
                            <img src="assets/images/email.png" alt="" />
                            {Email}
                          </span>
                        ) : null}
                        {Development > 0 ? (
                          <span title="Development">
                            <img src="assets/images/development.png" alt="" />
                            {Development}
                          </span>
                        ) : null}
                        {Design > 0 ? (
                          <span title="Design">
                            <img src="assets/images/design.png" alt="" />
                            {Design}
                          </span>
                        ) : null}
                        {Automation > 0 ? (
                          <span title="Automation">
                            <img src="assets/images/automation.png" alt="" />
                            {Automation}
                          </span>
                        ) : null}
                        {Reputation > 0 ? (
                          <span title="Reputation">
                            <img src="assets/images/Reputation.png" alt="" />
                            {Reputation}
                          </span>
                        ) : null}
                        {Other > 0 ? (
                          <span title="Other">
                            <img src="assets/images/Other.png" alt="" />
                            {Other}
                          </span>
                        ) : null}
                      </>
                    </Link>
                  </td>
                  <td className="status">
                    {member.status ? "active" : "deactive"}
                  </td>
                  <td className="action">
                    <Link
                      className="view-profile"
                      title="View"
                      to={`/teamMember-detail/${member?.id}`}
                    >
                      <img
                        src="assets/images/awesome-eye.png"
                        alt="View Profile"
                      />
                    </Link>
                    {/* <button className="view-profile"><img src="assets/images/awesome-eye.png" alt="View Profile" /></button> */}
                    <button
                      className="block-profile btn-danger"
                      title="Delete"
                      onClick={() => handleDeleteClick({ member })}
                    >
                      <img
                        src="assets/images/metro-blocked.png"
                        alt="Block Profile"
                      />
                    </button>

                    <Link to={`/teamMember-edit/${member?.id}`}>
                      <i className="fas fa-edit"></i>
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <span>
          {" "}
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="add-team-modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Disable Team Member
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            <div className="question-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="86.875"
                height="86.875"
                viewBox="0 0 86.875 86.875"
              >
                <path
                  id="Icon_awesome-question-circle"
                  data-name="Icon awesome-question-circle"
                  d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z"
                  transform="translate(-0.563 -0.563)"
                />
              </svg>
            </div>
            <p>Are you sure you want to Disable this Team Member ?</p>
          </div>
          <div className="form-btn-rows">
            <button
              type="button "
              onClick={handleConfirmDelete}
              className="btn global-icon-btn orange-btn "
            >
              Yes
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn global-icon-btn "
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default Teams;
