import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import API from "../../AxiosConfig";
import AddNewHolidayModal from "./AddNewHolidayModal";
import AskConfirmationModal from "./AskConfirmationModal";
import { useNavigate, useNavigation } from "react-router-dom";
import { pageTitle } from "../../features/actions/pageTitleActions";
import RejectRequestModal from "./RejectRequestModal";
import EditLeavePeriodModal from "./EditLeavePeriodModal";
import AddManualLeaveModal from "./AddManualLeaveModal";

function Holiday() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;
  const roleId = user.roleId;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    selectedUserId:"",
    startDate: "",
    endDate: "",
    category: "",
    comment: "",
    status: "Hold",
  });
  const [formError, setFormError] = useState({
    startDate: "",
    endDate: "",
    category: "",
    comment: "",
  });
  const [updateStatusData, setUpdateStatusData] = useState({
    id: "",
    status: "",
    rejectReason: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [showManualLeaveModal, setShowManualLeaveModal] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [holidays, setHolidaysList] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [formattedHolidaysList, setFormattedHolidaysList] = useState([]);
  const [formattedLeaves, setFormattedLeaves] = useState([]);
  const [confirmShow, setConfirmShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const title = "Holiday";

  let options = [
    { value: "PTO", label: "PTO" },
    { value: "Sick Day", label: "Sick Day" },
    { value: "Other", label: "Other" },
  ];
  const [updatedLeavePeriod, setUpdatedLeavePeriod] = useState({
    id: selectedLeave?.id,
    startDate: selectedLeave?.startDate
      ? new Date(selectedLeave.startDate)
      : null,
    endDate: selectedLeave?.endDate ? new Date(selectedLeave.endDate) : null,
  });

  useEffect(() => {
    if (selectedLeave) {
      setUpdatedLeavePeriod({
        id: selectedLeave.id,
        startDate: selectedLeave.startDate
          ? new Date(selectedLeave.startDate)
          : null,
        endDate: selectedLeave.endDate ? new Date(selectedLeave.endDate) : null,
      });
    }
  }, [selectedLeave]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Holiday/PTO", path: "/holiday", active: true },
      ])
    );
    dispatch(pageTitle(title));
  }, [dispatch, title]);

  const getHolidaysList = async () => {
    try {
      await API.get("/admin/holiday").then((d) => {
        setHolidaysList(d.data.holidaysList);
      });
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    getHolidaysList();
  }, [userId]);

  useEffect(() => {
    if (holidays.length > 0) {
      const sortedHolidays = holidays.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      const formattedList = sortedHolidays.map((holiday) => {
        const formattedDate = new Date(holiday.date).toLocaleDateString(
          "en-US",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );

        return {
          ...holiday,
          date: formattedDate,
        };
      });
      setFormattedHolidaysList(formattedList);
    }
  }, [holidays]);

  useEffect(() => {
    if (leaves.length > 0) {
      const formatDate = (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        return formattedDate;
      };

      const formattedList = leaves.map((leave) => {
        const formattedStartDate = formatDate(leave.startDate);
        const formattedEndDate = formatDate(leave.endDate);

        return {
          ...leave,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
      });

      setFormattedLeaves(formattedList);
    }
  }, [leaves]);

  const getAllLeaves = async () => {
    try {
      await API.get(`/admin/leave?userId=${userId}`).then((d) => {
        setLeaves(d.data.leaves);
        console.log("Leaves", d.data.leaves);
      });
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    getAllLeaves();
    const interval = setInterval(() => {
      getAllLeaves();
    }, 300000); // 5 minutes in milliseconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const saveLeave = async () => {
    try {
      const response = await API.post(
        `/admin/leave?userId=${userId}`,
        formData
      );
      if (response.data) {
        toast.success(response.data.message);
        getAllLeaves();
      }
      resetForm();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const updateLeaveStatus = async () => {
    try {
      const response = await API.put(
        `/admin/leave?userId=${userId}`,
        updateStatusData
      );
      if (response.data) {
        toast.success(response.data.message);
        getAllLeaves();
        setUpdateStatusData({ id: "", status: "", rejectReason: "" });
      }
    } catch (error) {
      console.log("Error while update leave status", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const updateSelectedLeavePeriod = async () => {
    try {
      const response = await API.put(
        `/admin/updateLeavePeriod?userId=${userId}`,
        updatedLeavePeriod
      );
      if (response.data) {
        toast.success(response.data.message);
        getAllLeaves();
        setUpdatedLeavePeriod({ id: "", startDate: "", endDate: "" });
      }
    } catch (error) {
      console.log("Error while update leave period", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const resetForm = () => {
    setFormData({ startDate: "", endDate: "", category: "", comment:"",selectedUserId:"" });
  };

  const onSubmit = () => {
    let errors = false;
    let error = { startDate: "", endDate: "", category: "" };
    if (formData.startDate === "") {
      errors = true;
      error.startDate = "StartDate is required.";
    }
    if (formData.endDate === "") {
      errors = true;
      error.endDate = "EndDate is required.";
    }
    if (errors) {
      setFormError(error);
      return false;
    }
    return true;
  };

  const handleClose = () => {
    const error = onSubmit();
    let errors = false;
    let closeError = { category: "", comment: "" };
    if (formData.category.trim() === "") {
      errors = true;
      closeError.category = "Category is required.";
    }
    if (formData.comment.trim() === "") {
      errors = true;
      closeError.comment = "Comment is required.";
    }
    if (errors) {
      setFormError(closeError);
    } else {
      setFormError({ ...formError, category: "", comment: "" });
    }
    if (error && formData.category.trim() && formData.comment.trim()) {
      setShowModal(false);
      saveLeave();
    }
  };

  const handleCancelCategory = () => {
    setFormData((prevData) => ({
      ...prevData,
      category: "",
      comment: "",
    }));
    setShowModal(false);
  };

  const handleDateChange = (date, type) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: date,
    }));
    if (type === "startDate") setFormError({ ...formError, startDate: "" });
    if (type === "endDate") setFormError({ ...formError, endDate: "" });
  };

  const handleCategoryChange = (option) => {
    const updatedStatus = roleId === 1 ? "Accept" : "Hold";
    setFormData((prevData) => ({
      ...prevData,
      category: option.value,
      status : updatedStatus
    }));
    
    setFormError({ ...formError, category: "" });
  };

  const handleCommentChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormError({ ...formError, comment: "" });
  };

  const handleApplyClick = () => {
    const result = onSubmit();
    if (result) setShowModal(true);
  };

  const renderHolidaysList = () => {
    return filteredFormattedHolidays?.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.date}</td>
          <td>{item.holiday}</td>
          <td>{item.notes}</td>
        </tr>
      );
    });
  };

  const handleOnClick = (id, status) => {
    setUpdateStatusData({ ...updateStatusData, id: id, status: status });
    if (status === "Accept") setConfirmShow(true);
    else setShowRejectModal(true);
  };

  const filteredFormattedHolidays = formattedHolidaysList.filter(
    (item) =>
      item.holiday.toLowerCase().includes(searchText.toLocaleLowerCase()) ||
      item.notes.toLowerCase().includes(searchText.toLocaleLowerCase())
  );

  const handleEditClick = (leave) => {
    setSelectedLeave(leave);
    setEditShow(true);
  };

  return (
    <>
      <div className="main-dashboard-admin holiday-pto">
        <div className="team-header-info">
          <div className="teamForm">
            <div className="checklist-header-wrap">
              <div className="progress-radio-out date-progress-section">
                <div className="searchBar">
                  <div className="field">
                    <input
                      type="search"
                      placeholder="Search Holiday"
                      onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                    />
                    <button type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <div className="select-date-pro">
                  <div className="select-heading">
                    <h4>Request PTO</h4>
                  </div>
                  <div className="dateBlock">
                    <div className="datePickerWrapper">
                      <DatePicker
                        selectsStart
                        selected={formData.startDate}
                        startDate={formData.startDate}
                        endDate={formData.endDate}
                        minDate={
                          new Date(new Date().setDate(new Date().getDate() + 1))
                        }
                        maxDate={formData.endDate}
                        onChange={(date) => handleDateChange(date, "startDate")}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Start Date"
                        className="datePickerInput"
                        customInput={
                          <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                            {(inputProps) => <input {...inputProps} />}
                          </InputMask>
                        }
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.738"
                        height="14.558"
                        viewBox="0 0 12.738 14.558"
                      >
                        <path
                          id="Icon_awesome-calendar-check"
                          data-name="Icon awesome-calendar-check"
                          d="M12.4,4.549H.341A.341.341,0,0,1,0,4.208V3.185A1.365,1.365,0,0,1,1.365,1.82H2.73V.341A.341.341,0,0,1,3.071,0H4.208a.341.341,0,0,1,.341.341V1.82h3.64V.341A.341.341,0,0,1,8.53,0H9.667a.341.341,0,0,1,.341.341V1.82h1.365a1.365,1.365,0,0,1,1.365,1.365V4.208A.341.341,0,0,1,12.4,4.549ZM.341,5.459H12.4a.341.341,0,0,1,.341.341v7.393a1.365,1.365,0,0,1-1.365,1.365H1.365A1.365,1.365,0,0,1,0,13.193V5.8A.341.341,0,0,1,.341,5.459ZM9.818,8.187l-.8-.807a.341.341,0,0,0-.483,0L5.52,10.369,4.212,9.051a.341.341,0,0,0-.483,0l-.807.8a.341.341,0,0,0,0,.483L5.269,12.7a.341.341,0,0,0,.483,0L9.816,8.67a.341.341,0,0,0,0-.483Z"
                        />
                      </svg>
                      {formError.startDate !== "" && (
                        <div className="text-danger">{formError.startDate}</div>
                      )}
                    </div>
                    <span>to</span>
                    <div className="datePickerWrapper">
                      <DatePicker
                        id="toDate"
                        selectsEnd
                        selected={formData.endDate}
                        startDate={formData.startDate}
                        endDate={formData.endDate}
                        minDate={
                          formData.startDate
                            ? new Date(formData.startDate)
                            : new Date(
                                new Date().setDate(new Date().getDate() + 1)
                              )
                        }
                        onChange={(date) => handleDateChange(date, "endDate")}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="End Date"
                        className="datePickerInput"
                        customInput={
                          <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                            {(inputProps) => <input {...inputProps} />}
                          </InputMask>
                        }
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.738"
                        height="14.558"
                        viewBox="0 0 12.738 14.558"
                      >
                        <path
                          id="Icon_awesome-calendar-check"
                          data-name="Icon awesome-calendar-check"
                          d="M12.4,4.549H.341A.341.341,0,0,1,0,4.208V3.185A1.365,1.365,0,0,1,1.365,1.82H2.73V.341A.341.341,0,0,1,3.071,0H4.208a.341.341,0,0,1,.341.341V1.82h3.64V.341A.341.341,0,0,1,8.53,0H9.667a.341.341,0,0,1,.341.341V1.82h1.365a1.365,1.365,0,0,1,1.365,1.365V4.208A.341.341,0,0,1,12.4,4.549ZM.341,5.459H12.4a.341.341,0,0,1,.341.341v7.393a1.365,1.365,0,0,1-1.365,1.365H1.365A1.365,1.365,0,0,1,0,13.193V5.8A.341.341,0,0,1,.341,5.459ZM9.818,8.187l-.8-.807a.341.341,0,0,0-.483,0L5.52,10.369,4.212,9.051a.341.341,0,0,0-.483,0l-.807.8a.341.341,0,0,0,0,.483L5.269,12.7a.341.341,0,0,0,.483,0L9.816,8.67a.341.341,0,0,0,0-.483Z"
                        />
                      </svg>
                      {formError.endDate !== "" && (
                        <div className="text-danger">{formError.endDate}</div>
                      )}
                    </div>
                    <button
                      className="global-icon-btn orange-btn"
                      onClick={handleApplyClick}
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="holiday-wrap">
          <div className="holiday-table">
            <div className="holidays-list">
              <h1 className="list-head">List of Holidays</h1>
              {roleId === 1 && (
                <div className="admin-control-buttons">
                  <button
                    className="add-manual-leave-button"
                    onClick={() => setShowManualLeaveModal(true)}
                  >
                    Add Manual Leave
                  </button>
                  <button
                    className="add-new-holidays-button"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Add New Holiday
                  </button>
                </div>
              )}
            </div>
            <div className="teamInfoTable teams-table">
              <table border="0" width="100%">
                <thead>
                  <tr>
                    <th className="name">Date</th>
                    <th className="title">Holiday</th>
                    <th className="email">Notes</th>
                  </tr>
                </thead>
                <tbody>{renderHolidaysList()}</tbody>
              </table>
            </div>
          </div>

          <div className="holiday-leaves">
            <div className="leaves-heading archived">
              <div className="list-heading">
              <h1 className="list-head">Employee Time Off Requests</h1>
              </div>
              <div className="archieved-wrapper">
                <button type="button" className="archieved-button" onClick={()=>navigate('/holiday/archieved')}>Archive</button>
              </div>
            </div>
            <div className="holiday-wrapper-leave">
              {formattedLeaves.length > 0 &&
                formattedLeaves.map((leave) => {
                  return (
                    <div key={leave.id} className="leave-box">
                      {roleId === 1 && (
                        <button
                          className="edit-leave-button"
                          onClick={() => handleEditClick(leave)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                      )}
                      <div className="leave-loop">
                        <div className="leave-info">
                          <div className="data-person">
                            <h6>Name</h6>
                            <span>
                              {leave.user.firstName} {leave.user.lastName}
                            </span>
                          </div>
                          <div className="data-person category">
                            <h6>Category</h6>
                            <span>{leave.category}</span>
                          </div>
                          <div className="data-person date">
                            <h6>Dates</h6>
                            <span>
                              {leave.startDate} - {leave.endDate}
                            </span>
                          </div>
                        </div>
                        {leave.status === "Accept" && (
                          <div className="approved-btn-container">
                            <div className="comment-reason">
                              Reason: {leave.comment}
                              <div className="apprve-btn">
                            <button>APPROVED</button>
                            </div>
                            </div>
                          </div>
                        )}
                        {leave.status === "Reject" && (
                          <div className="rejected-btn-container">
                            <div className="rejected-btn">
                            <div className="comment-reason">
                                Reason: {leave.comment}
                            </div>
                            <div className="reject-button-cum-message">
                            <button>Rejected</button>
                              {leave.rejectReason.trim() !== "" && (
                              <div className="reject-reason-display">
                                {leave.rejectReason}
                              </div>
                            )}
                            </div>
                              
                            </div>
                            
                          </div>
                        )}
                        {roleId === 1 && leave.status === "Hold" && (
                          <div className="container-with-comment">
                            <div className="comment-reason">
                              Reason: {leave.comment}
                            </div>
                            <div className="hold-container">
                              <div className="accept-btn">
                                <button
                                  onClick={() => {
                                    handleOnClick(leave.id, "Accept");
                                  }}
                                >
                                  Accept
                                </button>
                              </div>
                              <div className="reject-btn">
                                <button
                                  onClick={() => {
                                    handleOnClick(leave.id, "Reject");
                                  }}
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {roleId !== 1 && leave.status === "Hold" && (
                          <div className="apprve-btn">
                            <button>Pending</button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        show={showModal}
        onHide={handleCancelCategory}
        className="add-team-modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Select PTO Category
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            <div className="question-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="86.875"
                height="86.875"
                viewBox="0 0 86.875 86.875"
              >
                <path
                  id="Icon_awesome-question-circle"
                  data-name="Icon awesome-question-circle"
                  d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z"
                  transform="translate(-0.563 -0.563)"
                />
              </svg>
            </div>
          </div>
          <div className="reject-input-container">
            <Select
              options={options}
              value={options.find(
                (option) => option.value === formData.category
              )}
              onChange={handleCategoryChange}
              placeholder="Select Category"
              className="reject-input"
            />
          </div>
          {formError.category && (
            <div className="text-danger text-center">{formError.category}</div>
          )}
          <div className="comment-container">
            <label className="comment-label">Comment</label>
            <input
              className="comment-input"
              type="text"
              placeholder="Comment"
              name="comment"
              value={formData.comment}
              onChange={handleCommentChange}
            />
          </div>
          {formError.comment && (
            <div className="text-danger text-center">{formError.comment}</div>
          )}
          <div className="form-btn-rows">
            <button
              type="button "
              onClick={handleClose}
              className="btn global-icon-btn orange-btn "
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleCancelCategory}
              className="btn global-icon-btn "
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <AddNewHolidayModal
        show={show}
        setShow={setShow}
        getHolidaysList={getHolidaysList}
      />
      <AddManualLeaveModal
        showManualLeaveModal={showManualLeaveModal}
        setShowManualLeaveModal={setShowManualLeaveModal}
        options={options}
        formData={formData}
        setFormData={setFormData}
        saveLeave={saveLeave}
        resetForm={resetForm}
      />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AskConfirmationModal
        confirmShow={confirmShow}
        setConfirmShow={setConfirmShow}
        confirmationText="Do you really want to change leave status ?"
        confirmationTitle="Confirm Change Leave Status"
        updateLeaveStatus={updateLeaveStatus}
      />

      <RejectRequestModal
        showRejectModal={showRejectModal}
        setShowRejectModal={setShowRejectModal}
        updateLeaveStatus={updateLeaveStatus}
        updateStatusData={updateStatusData}
        setUpdateStatusData={setUpdateStatusData}
      />

      <EditLeavePeriodModal
        editShow={editShow}
        setEditShow={setEditShow}
        selectedLeave={selectedLeave}
        setSelectedLeave={setSelectedLeave}
        updatedLeavePeriod={updatedLeavePeriod}
        setUpdatedLeavePeriod={setUpdatedLeavePeriod}
        updateSelectedLeavePeriod={updateSelectedLeavePeriod}
      />
    </>
  );
}

export default Holiday;
