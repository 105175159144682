import React, { useEffect, useState } from 'react'
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import API from '../../AxiosConfig';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { pageTitle } from "../../features/actions/pageTitleActions";
import { useNavigate } from 'react-router-dom';

function Archieved() {
      const [formattedHolidaysList, setFormattedHolidaysList] = useState([]);    
      const [formattedLeaves, setFormattedLeaves] = useState([]);
      const [holidays, setHolidaysList] = useState([]);
      const [leaves, setLeaves] = useState([]);
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.id;
      const roleId = user.roleId;      
      const dispatch = useDispatch(); 
      const navigate = useNavigate();         
      const title = "Holiday";

      const getHolidaysList = async () => {
        try {
          await API.get(`/admin/holiday?archieved=archieved`).then((d) => {
            setHolidaysList(d.data.holidaysList);
          });
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }
      };

      const getAllLeaves = async () => {
        try {
          await API.get(`/admin/leave?userId=${userId}&archieved=archieved`).then((d) => {
            setLeaves(d.data.leaves);
            console.log("Leaves", d.data.leaves);
          });
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }
      };

      useEffect(() => {
          getAllLeaves();       
        },[]);

      useEffect(() => {
          getHolidaysList();
        }, [userId]);
    
 useEffect(() => {
     dispatch(
       setBreadcrumbs([
         { label: "Home", path: "/" },
         { label: "Holiday/PTO", path: "/holiday"},
         { label: "Archieved", path: "/holiday/archieved", active: true},
       ])
     );
     dispatch(pageTitle(title));
   }, [dispatch, title]);

    useEffect(() => {
        if (leaves.length > 0) {
          const formatDate = (date) => {
            const formattedDate = new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            });
    
            return formattedDate;
          };
    
          const formattedList = leaves.map((leave) => {
            const formattedStartDate = formatDate(leave.startDate);
            const formattedEndDate = formatDate(leave.endDate);
    
            return {
              ...leave,
              startDate: formattedStartDate,
              endDate: formattedEndDate,
            };
          });
    
          setFormattedLeaves(formattedList);
        }
      }, [leaves]);

      useEffect(() => {
          if (holidays.length > 0) {
            const sortedHolidays = holidays.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            );
            const formattedList = sortedHolidays.map((holiday) => {
              const formattedDate = new Date(holiday.date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
      
              return {
                ...holiday,
                date: formattedDate,
              };
            });
            setFormattedHolidaysList(formattedList);
          }
        }, [holidays]);       

    const renderHolidaysList = () => {
        return formattedHolidaysList?.map((item) => {
          return (
            <tr key={item.id}>
              <td>{item.date}</td>
              <td>{item.holiday}</td>
              <td>{item.notes}</td>
            </tr>
          );
        });
      };
  return (
    <div className='main-dashboard-admin holiday-pto archieved-container'>
        <div className="holiday-wrap">
          <div className="holiday-table">
          <div className="dis-head holidays-list back-list-wrapper">
           <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate('/holiday')}> <i className="fas fa-sign-out-alt"></i></button>
             <h1 className="list-head">List of Holidays</h1>           
           </div>            
            <div className="teamInfoTable teams-table">
              <table border="0" width="100%">
                <thead>
                  <tr>
                    <th className="name">Date</th>
                    <th className="title">Holiday</th>
                    <th className="email">Notes</th>
                  </tr>
                </thead>
                <tbody>{renderHolidaysList()}</tbody>
              </table>
            </div>
          </div>

          <div className="holiday-leaves">
            <div className="leaves-heading archived">
              <div className="list-heading">
              <h1 className="list-head">Employee Time Off Requests</h1>
              </div>
              <div className="archieved-wrapper">
                {/* <button type="button" className="archieved-button" onClick={()=>navigate('/holiday/archieved')}>Archieved</button> */}
              </div>
            </div>
            <div className="holiday-wrapper-leave">
              {formattedLeaves.length > 0 &&
                formattedLeaves.map((leave) => {
                  return (
                    <div key={leave.id} className="leave-box">
                      {/* {roleId === 1 && (
                        <button
                          className="edit-leave-button"
                          onClick={() => handleEditClick(leave)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                      )} */}
                      <div className="leave-loop">
                        <div className="leave-info">
                          <div className="data-person">
                            <h6>Name</h6>
                            <span>
                              {leave.user.firstName} {leave.user.lastName}
                            </span>
                          </div>
                          <div className="data-person category">
                            <h6>Category</h6>
                            <span>{leave.category}</span>
                          </div>
                          <div className="data-person date">
                            <h6>Dates</h6>
                            <span>
                              {leave.startDate} - {leave.endDate}
                            </span>
                          </div>
                        </div>
                        {leave.status === "Accept" && (
                          <div className="approved-btn-container">
                            <div className="comment-reason">
                              Reason: {leave.comment}
                              <div className="apprve-btn">
                            <button>APPROVED</button>
                            </div>
                            </div>
                          </div>
                        )}
                        {leave.status === "Reject" && (
                          <div className="rejected-btn-container">
                            <div className="rejected-btn">
                            <div className="comment-reason">
                                Reason: {leave.comment}
                            </div>
                            <div className="reject-button-cum-message">
                            <button>Rejected</button>
                              {leave.rejectReason.trim() !== "" && (
                              <div className="reject-reason-display">
                                {leave.rejectReason}
                              </div>
                            )}
                            </div>
                              
                            </div>
                            
                          </div>
                        )}
                        {roleId === 1 && leave.status === "Hold" && (
                          <div className="container-with-comment">
                            <div className="comment-reason">
                              Reason: {leave.comment}
                            </div>
                            <div className="hold-container">
                              <div className="accept-btn">
                                <button>
                                  Accept
                                </button>
                              </div>
                              <div className="reject-btn">
                                <button>
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {roleId !== 1 && leave.status === "Hold" && (
                          <div className="apprve-btn">
                            <button>Pending</button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>      
    </div>
  )
}

export default Archieved