import axios from "axios";

const API = axios.create({
    // baseURL: "http://127.0.0.1:5005/server",
  baseURL: 'https://basecamp.simpleseogroup.com/server',
  // baseURL: 'http://192.168.11.186:5004/server' ,
  // baseURL: 'http://192.168.11.54:5004/server'
  //  baseURL: 'http://20.115.96.161:3000/server'
  // baseURL: 'http://192.168.11.74:3000/server'
});

// Add a request interceptor
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Replace 'yourToken' with the actual key name
    if (token) {
      config.headers["Authorization"] = ` ${token}`; // Set token in Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
