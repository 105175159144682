import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import API from '../../AxiosConfig';
import { toast } from 'react-toastify';

const ProjectStatusBar = forwardRef(({handleTypeChange,formatCount,managerId},ref)=> {
    const initStatusCount = {
        "WorkInProgressCount": 0,
        "RecurringProjectsCount": 0,
        "OnHoldCount": 0,
        "QAReviewCount": 0,
        "PMReviewCount": 0,
        "ManagementReviewCount": 0,
        "ClientReviewCount": 0,
        "PendingGoLiveCount": 0,
        "CompleteCount": 0,
        "NoneCount": 0
    };
    const [projectStatusCount,setProjectCountStatus] = useState(initStatusCount);
    
    const getStatusCount = () => {
        API.get(`/admin/discussions-status-count?managerId=${managerId}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log("response", response.data);
                    setProjectCountStatus(response.data.data.statusCount); // Initialize filtered discussions
                } else {
                    toast.error("Error while getting the data", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch((error) => {
                toast.error("Error while getting the data", {
                    position: toast.POSITION.TOP_CENTER,
                });
                console.error(error);
            });
    };
 
    useImperativeHandle(ref, () => ({
        getStatusCount
      }));

    useEffect(()=>{
        getStatusCount();
    },[managerId]);
    return (
        <>
            <div className="stepsWrapper">
                <ul>
                           {
                           projectStatusCount.NoneCount>0 
                           &&
                            <li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'None' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.NoneCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>None</p>
                                    </div>
                                </div>
                            </li>
                            }

                            {
                            projectStatusCount.ClientReviewCount>0 
                            &&
                                <li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'Client Review' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.ClientReviewCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>Client Review</p>
                                    </div>
                                </div>
                            </li>
                            }
                            {
                            projectStatusCount.WorkInProgressCount>0 
                            &&
                            <li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'Work In Progress' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.WorkInProgressCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>Work In Progress</p>
                                    </div>
                                </div>
                            </li>
                            }
                             {
                           projectStatusCount.RecurringProjectsCount>0 
                           &&
                            <li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'Recurring Projects' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.RecurringProjectsCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>Recurring Projects</p>
                                    </div>
                                </div>
                            </li>
                            }
                           {  
                            projectStatusCount.PendingGoLiveCount>0 
                            &&
                            <li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'Pending Go-Live' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.PendingGoLiveCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>Pending Go Live</p>
                                    </div>
                                </div>
                            </li>
                            }
                           { 
                            projectStatusCount.QAReviewCount>0 
                            && <li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'QA Review' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.QAReviewCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>QA Review</p>
                                    </div>
                                </div>
                            </li>}
                            {
                            projectStatusCount.PMReviewCount>0 
                            &&<li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'PM Review' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.PMReviewCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>PM Review</p>
                                    </div>
                                </div>
                            </li>}
                            {
                            projectStatusCount.ManagementReviewCount>0 
                            &&<li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'Management Review' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.ManagementReviewCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>Management Review</p>
                                    </div>
                                </div>
                            </li>}
                            {
                            projectStatusCount.OnHoldCount>0 
                            &&<li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'On Hold' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.OnHoldCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>On Hold</p>
                                    </div>
                                </div>
                            </li>}
                            {
                            projectStatusCount.CompleteCount>0 
                            &&<li
                                className="items"
                                onClick={() => handleTypeChange({ target: { value: 'Complete' } })}
                            >
                                <div className="steps">
                                    <div className="countDigit">
                                        <span>{formatCount(projectStatusCount.CompleteCount)}</span>
                                    </div>
                                    <div className="amountBlock">
                                        <p>Complete</p>
                                    </div>
                                </div>
                            </li>}
                </ul>
            </div>
        </>
    )
})

export default ProjectStatusBar