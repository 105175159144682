import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Bars } from 'react-loader-spinner';
import Loader from '../Loader';
import { debounce } from 'lodash';

function Discussions() {
    const dispatch = useDispatch();
    const title = 'Discussions';
    const [discussions, setDiscussion] = useState([]);
    const { id } = useParams();
    const [value, setValue] = useState('');
    const [expandedDiscussion, setExpandedDiscussion] = useState(null); 
    const [dropdownOpen, setDropdownOpen] = useState(null); 
    const [newStatus, setNewStatus] = useState(''); 
    const navigate = useNavigate();
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;
    const [loading, setLoading] = useState(false);
    const [discussionPage,setDiscussionPage] = useState(1);

  const [scrollCount, setScrollCount] = useState(0);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [disableLoadMore,setDisableLoadMore] = useState(false);
  const [dataLoader,setDataLoader] = useState(false)

    const getDiscussionsOfProject = (numberofpages =10,resetDiscussions=false) => {
        if(discussionPage == 1)
            setLoading(true);
        if(discussionPage > 1)
            setDataLoader(true)
        const page = (resetDiscussions)?1:discussionPage;
        API.get(`/admin/get-discussions-project/${id}?page=${page}&size=${numberofpages}`)
            .then((response) => {
                setLoading(false);
            setDataLoader(false)
                if(response?.data?.data?.projectDiscussion?.length < 9)
                    setDisableLoadMore(true);
                const sortedDiscussions = response.data.data.projectDiscussion.sort((a, b) => {
                    const dateA = getDiscussionDate(a);
                    const dateB = getDiscussionDate(b);
                    if (isNaN(dateA) || isNaN(dateB)) {
                        console.error('Invalid date format:', a, b);
                        return 0; // Skip sorting if any date is invalid
                    }
                    return dateB - dateA;
                });
                if(resetDiscussions){
                    setDiscussion(sortedDiscussions);
                }else{
                    setDiscussion([...discussions,...sortedDiscussions]);
                }
                    setDiscussionPage(response.data.data.page+1);
            })
            .catch((error) => {
                setLoading(false);
                setDataLoader(false)
                console.error("Error fetching discussions:", error);
            });
    }

    const getDiscussionDate = (discussion) => {
        if (discussion.comments && discussion.comments.length > 0) {
            discussion.comments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            const lastCommentDate = new Date(discussion.comments[discussion.comments.length - 1].createdAt);
            if (!isNaN(lastCommentDate)) {
                return lastCommentDate; 
            }
        }
        const discussionDate = new Date(discussion.createdAt);
        return discussionDate; 
    }


    const handleSearch = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    const filterBySearch = discussions.filter(discussion => {
        if (typeof value === 'undefined' || value.trim() === '') {
            return discussion;
        } else {
            const lowercasedValue = value.toLowerCase();
            return (
                discussion?.title.toLowerCase().includes(lowercasedValue) ||
                discussion?.description.toLowerCase().includes(lowercasedValue) ||
                discussion?.addedByUser?.firstName.toLowerCase().includes(lowercasedValue) ||
                new Date(discussion?.createdAt).toLocaleString().includes(value)
            );
        }
    });

    useEffect(() => {
        dispatch(pageTitle(title));
        getDiscussionsOfProject();
    }, []);

    const handleReadMoreToggle = (discussionId) => {
        setExpandedDiscussion(expandedDiscussion === discussionId ? null : discussionId);
    }

    const truncateDescription = (description) => {
        if (description.length > 50) {
            return `${description.slice(0, 50)}...`;
        }
        return description;
    }

    const handleStatusClick = (discussionId) => {
        setDropdownOpen(dropdownOpen === discussionId ? null : discussionId); // Toggle dropdown visibility
    };

    const handleStatusChange = async (discussionId, newStatus) => {
        const oldStatus = discussions.find(discussion => discussion.id === discussionId)?.status;
        const currentDate = new Date().toLocaleDateString();
        setDiscussion(discussions.map(discussion =>
            discussion.id === discussionId ? { ...discussion, status: newStatus } : discussion
        ));
        try {
            await API.put(`/admin/updateStatus`, { status: newStatus, id: discussionId });
            await getDiscussionsOfProject(discussions.length,true); // Refresh the discussion list
        } catch (error) {
            console.error('Error updating status or posting comment:', error);
        }
    }

    const handleScroll = debounce((event, editor) => {
        // if(disableLoadMore)
            return;
        const currentScrollPosition = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;
        // Check if we've reached the bottom
        if (scrollHeight - currentScrollPosition <= clientHeight) {
            getDiscussionsOfProject();
        } 
      },300);
    const loadMoreHandler = (event)=>{
        getDiscussionsOfProject();

    }
    return (

        <div className='main-dashboard-admin'>
            {loading && (

                    <div className="rotate-line-loader">
                      <Bars
                        visible={true}
                        height="150"
                        width="150"
                        color="orange"
                        strokeColor="orange"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                      />
                    </div>
                  )}
            <div className='discussion-bar'>
                <div className="dis-head">
                    <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(`/clients/client-detail/project-detail/${id}`)}> <i className="fas fa-sign-out-alt"></i></button>
                    <h6>Discussions</h6>
                </div>
                <div className='search-bar'>
                    <div className='dis-btn'>
                        <Link to={`/clients/client-detail/project-detail/discussions/new-discussion/${id}`}>New Discussion</Link>
                    </div>
                </div>
            </div>

            <div onScroll={handleScroll} className="teamInfoTable discussion-table">
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="data">ID</th>
                            <th className="name">User</th>
                            <th className="projectName">Discussion Title</th>
                            <th className="message">Description</th>
                            <th className="createdAt">Last Updated</th>
                            <th className="status">Status</th>
                            <th>Comments</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? null : 
                            filterBySearch?.length > 0 ?
                                filterBySearch.map((discussion) => (
                                    <tr key={discussion?.id}>
                                        <td className="projectName">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>D - {discussion?.serialNo}</Link>
                                        </td>
                                        <td className="name">
                                        {discussion?.comments?.length > 0 ? (
                                            <img src={discussion?.comments[discussion?.comments.length - 1]?.user?.profilePictureUrl} />
   
                                            ) : (
                                                <img src={discussion?.addedByUser?.profilePictureUrl} />
                                            )}

                                            <p>
                                                {discussion?.comments?.length > 0 
                                                    ? discussion?.comments[discussion?.comments.length - 1]?.user?.firstName
                                                    : discussion?.addedByUser?.firstName
                                                }
                                            </p>

                                        </td>
                                        <td className="message">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>
                                                <p>{discussion?.title}</p>
                                            </Link>
                                        </td>

                                        <td className="createdAt">
                                            <p>
                                                
                                                {expandedDiscussion === discussion.id
                                                    ? new DOMParser().parseFromString(
                                                        discussion?.comments[discussion?.comments?.length - 1]?.comment ?? discussion?.description,
                                                        'text/html'
                                                    ).body.textContent
                                                    : truncateDescription(
                                                        new DOMParser().parseFromString(
                                                            discussion?.comments[discussion?.comments?.length - 1]?.comment ?? discussion?.description,
                                                            'text/html'
                                                        ).body.textContent
                                                    )
                                                }
                                            </p>
                                            {(
                                                new DOMParser().parseFromString(
                                                    discussion.comments[discussion.comments.length - 1]?.comment ?? discussion.description,
                                                    'text/html'
                                                ).body.textContent.length > 50
                                            ) && (
                                                    <button onClick={() => handleReadMoreToggle(discussion.id)}>
                                                        {expandedDiscussion === discussion.id ? "Read Less" : "Read More"}
                                                    </button>
                                                )}
                                        </td>

                                        <td className="view">
                                            <p>
                                                {discussion.comments.length > 0 && discussion.comments[discussion.comments.length - 1].createdAt
                                                    ? new Date(discussion.comments[discussion.comments.length - 1].createdAt).toLocaleString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true, // Ensures AM/PM format
                                                    })
                                                    : new Date(discussion.createdAt).toLocaleString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                    })}
                                            </p>
                                        </td>
                                        <td className="view view-dropdown" onClick={() => handleStatusClick(discussion?.id)}>
                                            <div className='view-d-flex'>{discussion?.status}<div class="dropIcon"><svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg></div></div>
                                            {dropdownOpen === discussion?.id && (
                                                <div className="dropdown">
                                                    <ul>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'On Hold')}>On Hold</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Work In Progress')}>Work In Progress</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Recurring Projects')}>Recurring Projects</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'QA Review')}>QA Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'PM Review')}>PM Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Management Review')}>Management Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Client Review')}>Client Review</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Pending Go-Live')}>Pending Go-Live</li>
                                                        <li onClick={() => handleStatusChange(discussion?.id, 'Complete')}>Complete</li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        <td className="com">
                                            {discussion?.comments?.length === 0 ? 'No' : discussion?.commentCount} Comments
                                        </td>
                                        <td className="com">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>View</Link>
                                        </td>
                                    </tr>
                                ))
                                : 'No discussion found'
                        }
                    </tbody>
                </table>
                {
                    (!disableLoadMore) &&
                    <>
                    {
                    (!dataLoader) && 
                    <div className='load-more-btn text-center'>
                        <button onClick={loadMoreHandler} className='load-more global-icon-btn blue-btn'>Load More</button>
                    </div>
                    }
                
                    {
                    (dataLoader) && 
                    <Loader/>
                    }
                    </>
                }
                
            </div>
           
        </div>


    );
}

export default Discussions;
